import React, { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import CarouselCard from './CarouselCard';
import LeftArrow from '../assets/icons/leftArrow.svg';
import CarouselCardReview from './CarouselCardReview';

const StyledSlider = styled(Slider)`
  margin: 1rem -0.5rem;
  height: 335px;
  @media (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const NextArrow = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -75px;
  right: 0.5rem;
  width: 49px;
  height: 49px;
  padding-left: 2px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(129, 129, 165, 0.35);
  color: #13273f;
  font-size: 40px;
  border: none;
  cursor: pointer;
  transition: 300ms;
  z-index: 5;
  &:hover {
    background-color: #13273f;
    color: white;
  }
`;

const PrevArrow = styled(NextArrow)`
  right: 71px;
  transition: 300ms;
  padding-left: 0;
  padding-right: 2px;
  &:hover {
    background-color: #13273f;
    color: white;
  }
`;

const RightArrow = styled(LeftArrow)`
  transform: rotate(180deg);
`;

interface Item {
  title: string;
  text: string;
  image: string;
  link?: string;
  name?: string;
}
interface CarouselProps {
  items: Item[];
  imgMarginBottom?: string;
  forReviews?: boolean;
  slidesNum?: number;
}

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <NextArrow onClick={onClick}>
      <RightArrow />
    </NextArrow>
  );
};
const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <PrevArrow onClick={onClick}>
      <LeftArrow />
    </PrevArrow>
  );
};

const Carousel: React.FunctionComponent<CarouselProps> = ({
  items,
  imgMarginBottom,
  forReviews,
  slidesNum,
}) => {
  const [slidesCount, onSlidesCountChange] = useState(slidesNum ?? 4);
  useEffect(() => {
    if (window.innerWidth < 640) {
      onSlidesCountChange(1);
    } else if (window.innerWidth < 950) {
      onSlidesCountChange(2);
    } else if (window.innerWidth < 1200) {
      onSlidesCountChange(3);
    }
  }, []);
  const settings: Settings = {
    arrows: true,
    speed: 500,
    slidesToShow: slidesCount,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <StyledSlider {...settings}>
      {items.map((item) =>
        forReviews ? (
          <CarouselCardReview
            imgMarginBottom={imgMarginBottom}
            key={item.title}
            title={item.title}
            text={item.text}
            cardImgSrc={item.image}
            learnMore={item.link}
            name={item?.name}
          />
        ) : (
          <CarouselCard
            imgMarginBottom={imgMarginBottom}
            key={item.title}
            title={item.title}
            text={item.text}
            cardImgSrc={item.image}
            learnMore={item.link}
          />
        ),
      )}
    </StyledSlider>
  );
};

export default Carousel;
