import React from 'react';
import styled from 'styled-components';
import { Body4Bold, P, Subtitle5 } from './Typography';

const Wrapper = styled.div<{ flexBasis?: string; marginBottom?: string }>`
  flex-basis: ${({ flexBasis }) => (flexBasis ? `${flexBasis}` : `30%`)};
  display: flex;
  height: calc(335px - 45px);
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 50px 30px 36px;
  margin: 1rem 0.5rem;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  border: 1px solid transparent;
  cursor: pointer;
  box-shadow: 0px 0rem 0.5rem 0px #e7edf3;
  transition: 0.4s;

  &:hover {
    box-shadow: 0px 0rem 1rem 0px rgba(129, 129, 165, 0.2);
    border: 1px solid #c4dbf0;
  }
  @media (max-width: 1200px) {
    flex-grow: 1;
    margin-bottom: 2rem;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const Img = styled.img<{ imgMarginBottom: string }>`
  width: 70px;
  height: 70px;
  margin-right: 1rem;
`;

const Title = styled(Subtitle5)`
  margin-bottom: 0.5rem;
`;

const LearnMore = styled.a`
  text-transform: underline;
  color: #13273f;
`;

const StyledBody4Bold = styled(Body4Bold)`
  margin-top: 1.5rem;
`;
interface SolutionComponentProps {
  title: string;
  text: string;
  learnMore?: string;
  cardImgSrc: string;
  flexBasis?: string;
  marginBottom?: string;
  imgMarginBottom?: string;
  className?: string;
  name?: string;
}
const CarouselCardReview: React.FunctionComponent<SolutionComponentProps> = ({
  title,
  text,
  learnMore,
  cardImgSrc,
  flexBasis,
  marginBottom,
  imgMarginBottom,
  className,
  name,
}) => (
  <Wrapper
    flexBasis={flexBasis}
    marginBottom={marginBottom}
    className={className}
  >
    <div>
      <Header>
        <Img
          imgMarginBottom={imgMarginBottom}
          src={cardImgSrc}
          alt="card logo"
        />
        <Title>{title}</Title>
      </Header>
      <P>{text}</P>
    </div>
    {name && <StyledBody4Bold>{name}</StyledBody4Bold>}
    {learnMore && (
      <LearnMore href={learnMore}>
        <StyledBody4Bold>Learn more</StyledBody4Bold>
      </LearnMore>
    )}
  </Wrapper>
);

export default CarouselCardReview;
